import { baseRequest } from './index';

/**
 * 商机管理列表
 * @params data Object
 * @returns MenusInfo
*/
export const businessList = (data, aid) => baseRequest.post('/crmapp/busoppo/list', data, {
  headers: {
    'aid': aid
  }
});

/**
 * 商机详情
 * @params oppoId int
 * @returns UserInfo
*/
export const businessDetailt = (oppoId, aid) => baseRequest.post('/crmapp/busoppo/detail', {oppoId}, {
  headers: {
    'aid': aid
  }
});

/**
 * 商机行业类型
 * @params oppoId int
 * @returns UserInfo
*/
export const findList = () => baseRequest.post('/crmapp/industry/findList');

/**
 * 商机保存
 * @params data Object
 * @returns MenusInfo
*/
export const businessSave = (data) => baseRequest.post('/crmapp/busoppo/save', data);

/**
 * 商机修改
 * @params data Object
 * @returns MenusInfo
*/
export const businessUpdate = (data) => baseRequest.post('/crmapp/busoppo/update', data);

/**
 * 商机编辑详情
 * @params oppoId string
 * @returns UserInfo
*/
export const businessEditDetail = (oppoId) => baseRequest.post('/crmapp/busoppo/editDetail', {oppoId});

/**
 * 获取业务类型
 * @params status int
 * @returns UserInfo
 */
export const busTypeList = (status) => baseRequest.post('/crmapp/busType/list', {status});

/**
 * 根据业务类型获取对应的产品
 * @params busTypeIds string
 * @params rootOrgId int
 * @returns UserInfo
 */
export const selectTitle = (busTypeIds, rootOrgId) => baseRequest.post('/crmapp/combo/selectTitle', {busTypeIds, rootOrgId});

