<template>
  <div class="cust-box">
    <div class="cust-content">
      <view-match :list="basicList" />
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays, findValueByKey } from '@/lib/until.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    viewMatch
  },
  data() {
    return {
      basicData: {
        oppoCode: {
          title: '商机编号',
          value: ''
        },
        custCn: {
          title: '客户名称',
          value: ''
        },
        busTypeCn: {
          title: '业务类型',
          value: ''
        },
        comboName: {
          title: '产品名称',
          value: ''
        },
        oppoLevel: {
          title: '商机等级',
          value: ''
        },
        fatherIndustryList: {
          title: '行业类型',
          value: ''
        },
        businessSource: {
          title: '商机来源',
          value: ''
        },
        custContactsName: {
          title: '本次联系人',
          value: ''
        },
        hasSupplier: {
          title: '是否供应商',
          value: ''
        },
        supplierName: {
          title: '供应商名称',
          value: ''
        },
        // expectedCooperation: {
        //   title: '预计合作人数',
        //   value: ''
        // },
        remark: {
          title: '备注',
          value: ''
        }
      }
    };
  },
  computed: {
    ...mapGetters(['dictList']),
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    }
  },
  methods: {
    getData(data) {
      for (let i in this.basicData) {
        this.basicData[i].value = data[i];
      }
      this.basicData.oppoLevel.value = findValueByKey(this.dictList.oppoLevel, data.oppoLevel);
      this.basicData.businessSource.value = findValueByKey(this.dictList.clueSource, data.businessSource);
      this.basicData.hasSupplier.value = data.hasSupplier !== null ? data.hasSupplier === 1 ? '是' : '否' : '';
      let str = '';
      data.fatherIndustryList && data.fatherIndustryList.forEach(item => {
        str += item.moduleName + '/';
      });
      this.basicData.fatherIndustryList.value = str.slice(0, str.length - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.cust-box{
  width:100%;
  overflow: hidden;
  text-align: left;
  background-color: $color-FFF;
  .cust-content{
    margin: 22px 14px 0 14px;
    .cust-title{
      margin-bottom: 24px;
      color:$color-333;
      font-weight: 600;
      font-size:$font-14;
    }
  }
}

</style>
