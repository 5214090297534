<template>
  <div class="section">
    <cust-header
      class="header"
      :data="busoppoInfoDetail"
    />
    <tab-composite
      ref="tabComposite"
      class="main"
      fixed-height="168"
      :need-btn="needBtn"
      :tab-list="tabList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      :need-border="false"
      @getIndex="getIndex"
    >
      <ul
        slot="1"
        class="list-wrapper"
      >
        <bus-info ref="busInfo" />
      </ul>
      <ul
        slot="2"
        class="list-wrapper"
      >
        <!--有权限 -->
        <div
          v-if="$route.params.type === '1'"
          class="outing"
        >
          <div
            v-for="(item, indexs) of trackInfoDetailList"
            :key="indexs"
            class="out-box"
            @click="toFollowDetail(item.id)"
          >
            <card-timeline :data="item.mainObj">
              <span class="card-course">{{ `进程推进：${item.mainObj.trackReliable}` }}</span>
            </card-timeline>
          </div>
          <error-page v-if="!trackInfoDetailList.length" />
        </div>
        <!--没有权限 没有人员信息-->
        <div
          v-else
          class="outing"
        >
          <div
            v-for="(item, indexs) of trackInfoDetailList"
            :key="indexs"
            class="out-box"
            @click="toFollowDetail(item.id)"
          >
            <card-without :data="item.mainObj">
              <span class="card-d">{{ `进程推进：${item.mainObj.trackReliable}` }}</span>
            </card-without>
          </div>
          <error-page v-if="!trackInfoDetailList.length" />
        </div>
      </ul>
      <ul
        slot="3"
        class="list-wrapper"
      >
        <cust-info ref="custInfo" />
      </ul>
      <ul
        slot="4"
        class="list-wrapper"
      >
        <card-contacts
          v-for="(item, indexs) of custContactsDetailList"
          :key="indexs"
          :data="item.mainObj"
        />
        <error-page v-if="!custContactsDetailList.length" />
      </ul>
      <ul
        v-if="$route.params.type === '1'"
        slot="5"
        class="list-wrapper"
      >
        <sales-info ref="salesInfo" />
      </ul>
    </tab-composite>
    <div class="bottom-postion">
      <cube-button
        v-if="index === 0 && btnAid.includes('edit')"
        class="btn"
        @click="toBusiness"
      >
        编辑商机
      </cube-button>
      <cube-button
        v-if="index === 1 && btnAid.includes('addfollow')"
        class="btn"
        @click="toFollow"
      >
        添加跟进
      </cube-button>
      <cube-button
        v-if="index === 3 && btnAid.includes('addcont')"
        class="btn"
        @click="toContact"
      >
        添加联系人
      </cube-button>
    </div>
  </div>
</template>

<script >
import { mapGetters } from 'vuex';

import { businessDetailt } from '_api/bus';
import custHeader from '_c/cust-header';
import errorPage from '_c/errorPage';
import cardContacts from '_c/tab-card/card-contacts';
import cardTimeline from '_c/tab-card/card-timeline';
import cardWithout from '_c/tab-card/card-without';
import tabComposite from '_c/tab-composite';

import { findValueByKey } from '@/lib/until';
import btnAuth from '@/mixins/btnAuth';
import busInfo from '@/views/business/components/business-info';
import salesInfo from '@/views/business/components/sales-info';
import custInfo from '@/views/cust/components/cust-info';

export default {
  name: 'BusinessDetails',
  components: {
    custHeader,
    tabComposite,
    cardTimeline,
    cardContacts,
    custInfo,
    busInfo,
    salesInfo,
    cardWithout,
    errorPage
  },
  mixins: [btnAuth],
  data() {
    return {
      busoppoInfoDetail: {}, // 头部信息
      businessDetails: {}, // 商机信息
      trackInfoDetailList: [], // 跟进信息
      custInfoDetail: {},
      custContactsDetailList: [], // 联系人信息
      sellUserInfoDetail: {}, // 销售信息
      tabList: [{
        label: '商机信息',
        value: 0
      }, {
        label: '跟进信息',
        value: 1
      }, {
        label: '客户信息',
        value: 2
      }, {
        label: '联系人',
        value: 3
      }],
      index: 0,
      loadEnable: false,
      aid: ''
    };
  },
  computed: {
    ...mapGetters('business', ['aidList']),
    ...mapGetters(['dictList']),
    needBtn() {
      // if ([2, 4].includes(this.index)) {
      //   return false;
      // } else {
      //   return true;
      // }
      if (this.index === 0 && this.btnAid.includes('edit')) {
        return true;
      } else if (this.index === 1 && this.btnAid.includes('addfollow')) {
        return true;
      } else if (this.index === 3 && this.btnAid.includes('addcont')) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.getTabList();
  },
  mounted() {
    this.getDetails(this.$route.params.aid);
  },
  methods: {
    getDetails(aid) {
      businessDetailt(this.$route.params.busId, aid).then((res) => {
        if (res.flag) {
          // 头部信息
          const busoppoInfoDetail = res.data.busoppoInfoDetail;
          this.busoppoInfoDetail = {
            id: busoppoInfoDetail.id,
            oppoCode: busoppoInfoDetail.oppoCode,
            title: busoppoInfoDetail.custCn,
            status: findValueByKey(this.dictList.oppoStatus, busoppoInfoDetail.oppoStatus),
            comboName: busoppoInfoDetail.comboName,
            mainList: [
              `商机产品：${busoppoInfoDetail.comboName}`,
              `商机等级：${findValueByKey(this.dictList.oppoLevel, busoppoInfoDetail.oppoLevel)}`,
              `进程推进：${findValueByKey(this.dictList.reliable, busoppoInfoDetail.trackReliable)}`
            ],
            busTypeIds: busoppoInfoDetail.busTypeIds
          };
          // 商机信息
          this.businessDetails = JSON.parse(JSON.stringify(busoppoInfoDetail));
          this.$refs.busInfo.getData(this.businessDetails);
          // 跟进信息
          this.trackInfoDetailList = res.data.trackInfoDetailList || [];
          this.trackInfoDetailList.forEach(item => {
            item.mainObj = {
              trackTime: item.trackDate ? item.trackDate.substring(5, item.trackDate.length) : '',
              trackYear: item.trackDate ? item.trackDate.substring(0, 4) : '',
              name: item.userTitle,
              trackType: findValueByKey(this.dictList.trackType, item.trackType),
              branchOrgTitle: item.branchOrgTitle,
              orgTitle: item.orgTitle,
              trackReliable: findValueByKey(this.dictList.reliable, item.trackReliable),
              trackRemark: item.trackRemark || ''
            };
          });
          // 客户信息
          this.custInfoDetail = res.data.custInfoDetail;
          this.$refs.custInfo.getData(this.custInfoDetail);
          // 联系人信息
          this.custContactsDetailList = res.data.custContactsDetailList;
          this.custContactsDetailList.forEach(item => {
            item.mainObj = {
              name: item.name,
              position: item.position,
              mobile: item.mobile
            };
          });
          // 负责销售信息
          if (this.$refs.salesInfo) this.$refs.salesInfo.getData(res.data.sellUserInfoDetail);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
    },
    getTabList() {
      const type = this.$route.params.type;
      if (type === '1') {
        this.tabList.push({ label: '负责销售', value: 4 });
      }
      // 判断按钮权限
      this.getAuthBtn(type);
    },
    toFollowDetail(id) {
      this.$router.push(
        {
          name: 'follow-details',
          params: {
            followId: id
          }
        }
      );
    },
    toBusiness() {
      if ([1, 2, 4, 8].includes(this.businessDetails.oppoStatus)) {
        this.$router.push(
          {
            name: 'AddeditBusiness',
            query: {
              businessId: this.businessDetails.id
            }
          }
        );
      } else {
        this.$showToast('未跟进、跟进中，暂无意向和锁定的可以编辑');
      }
    },
    toFollow() {
      this.$router.push(
        {
          name: 'AddeditFollow',
          query: {
            custCn: this.custInfoDetail.custCn,
            custId: this.custInfoDetail.id,
            oppoCode: this.busoppoInfoDetail.oppoCode,
            oppoId: this.busoppoInfoDetail.id,
            comboName: this.busoppoInfoDetail.comboName,
            busTypeId: this.busoppoInfoDetail.busTypeIds,
            from: 'fromBus'
          }
        }
      );
    },
    toContact() {
      this.$router.push(
        {
          name: 'addedit-contact',
          query: {
            custCn: this.custInfoDetail.custCn,
            custId: this.custInfoDetail.id
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>

<style  lang="scss"  scoped>
.header{
  width:100%;
  height:168px;
}
.outing{
  .out-box:first-child{
    .card-box{
      padding-top:24px ;
    }
  }
  .out-box:last-child{
    .card-box{
      padding-bottom:24px ;
    }
  }
}
</style>
